import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Hero = () => {
  return (
    <Box 
      sx={{ 
        height: '40vh',
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'url("/d3.JPG")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.3)',
          zIndex: -1
        }
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center', position: 'relative', pt: 8 }}>
        <Typography 
          variant="h1" 
          sx={{
            fontFamily: 'Playfair Display',
            color: 'white',
            fontSize: { xs: '2.5rem', md: '4rem' },
            mb: 2
          }}
        >
          Nos Chalets
        </Typography>
        <Typography 
          sx={{
            fontFamily: 'Raleway',
            color: '#F5E6D3',
            fontSize: { xs: '1.1rem', md: '1.3rem' },
            maxWidth: '600px',
            margin: '0 auto'
          }}
        >
          Découvrez nos quatre chalets, alliant confort moderne et charme authentique
        </Typography>
      </Container>
    </Box>
  );
};

export default Hero;