import React from 'react';
import './HeroBanner.css';

const HeroBanner = () => {
  const handleButtonClick = () => {
    const featuresSection = document.getElementById('features-section');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="hero-banner">
      <div className="hero-content">
        <h1>Découvrez la magie de la Corse avec les Chalets De Tesa</h1>
        <p>Un séjour paisible et ressourçant au cœur de la nature corse.</p>
        <button className="hero-button" onClick={handleButtonClick}>
          Découvrir
        </button>
      </div>
    </section>
  );
}

export default HeroBanner;