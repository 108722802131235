import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const Video = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    
    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
      }
    };
  }, []);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch(error => {
          console.log("Lecture automatique empêchée:", error);
        });
      }
    }
  };

  return (
    <Box sx={{ mb: 8, position: 'relative' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gap: 4,
          backgroundColor: 'white',
          p: 4,
          borderRadius: 2,
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        }}
      >
        {/* Video Container */}
        <Box
          sx={{
            position: 'relative',
            height: '400px',
            borderRadius: 1,
            overflow: 'hidden',
          }}
        >
          <video
            ref={videoRef}
            loop
            muted
            playsInline
            poster="/i4.JPG" 
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src="/video.mp4" type="video/mp4" />
            <source src="/video.webm" type="video/webm" />
            Votre navigateur ne prend pas en charge la lecture de vidéos.
          </video>
          <Box
            onClick={togglePlay}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: isPlaying ? 'transparent' : 'rgba(0,0,0,0.3)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              opacity: isPlaying ? 0 : 1,
              '&:hover': {
                opacity: 1,
                backgroundColor: 'rgba(0,0,0,0.4)',
              }
            }}
          >
            <IconButton
              sx={{
                backgroundColor: 'rgba(255,255,255,0.9)',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,1)',
                }
              }}
            >
              {isPlaying ? 
                <PauseIcon sx={{ fontSize: 40, color: '#c19a6b' }} /> :
                <PlayArrowIcon sx={{ fontSize: 40, color: '#c19a6b' }} />
              }
            </IconButton>
          </Box>
        </Box>

        {/* Text Content */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Playfair Display',
              color: '#1a1a1a',
              mb: 3
            }}
          >
            Vivez l'expérience Corse
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Raleway',
              color: '#666',
              fontSize: '1.1rem',
              mb: 4
            }}
          >
            Immergez-vous dans l'ambiance unique de nos chalets où chaque détail a été pensé pour votre confort.
            Entre mer et montagne, profitez d'un concept insolite au milieu d'un haras équestre pour des vacances mémorables.
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Playfair Display',
              color: '#c19a6b',
              fontSize: '1.2rem',
              fontStyle: 'italic'
            }}
          >
            "Un havre de paix au cœur de l'île de beauté"
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Video;