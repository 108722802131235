import React from 'react';
import { motion } from 'framer-motion';

const SlideInAlternating = ({ children, delay = 0, fromLeft = true }) => {
    return (
        <motion.div
        initial={{ opacity: 0, x: fromLeft ? -100 : 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, margin: "-100px" }}
        transition={{
            duration: 0.7,
            delay: delay,
            ease: "easeOut"
        }}
        >
        {children}
        </motion.div>
    );
};

export default SlideInAlternating;
