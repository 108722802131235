import React from 'react';
import { Box, Container } from '@mui/material';
import { motion } from 'framer-motion';
import Hero from '../components/chalets/Hero';
import Video from '../components/chalets/Video';
import Carousel from '../components/chalets/Carousel';
import Amenities from '../components/chalets/Amenities';
import Tarifs from '../components/chalets/Tarifs';
import Separator from '../components/chalets/Separator';
import SlideInAlternating from '../animations/SlideInAlternating';

const NosChalets = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Hero />
      </motion.div>
      
      <Box sx={{ 
        backgroundColor: '#f5f5f5',
        position: 'relative',
        py: 6,
        overflow: 'hidden'
      }}>
        <Container maxWidth="lg">
          <SlideInAlternating delay={0.1} fromLeft={true}>
            <Video />
          </SlideInAlternating>

          <SlideInAlternating delay={0.2} fromLeft={true}>
            <Separator />
          </SlideInAlternating>

          <SlideInAlternating delay={0.2} fromLeft={false}>
            <Amenities />
          </SlideInAlternating>

          <SlideInAlternating delay={0.2} fromLeft={true}>
            <Carousel />
          </SlideInAlternating>

          <SlideInAlternating delay={0.3} fromLeft={true}>
            <Separator />
          </SlideInAlternating>

          <SlideInAlternating delay={0.4} fromLeft={false}>
            <Tarifs />
          </SlideInAlternating>
         
        </Container>
      </Box>
    </>
  );
};

export default NosChalets;