import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { MapPin, Clock, Navigation } from 'lucide-react';
import SlideInAlternating from '../../animations/SlideInAlternating';

const BaladeCheval = () => {
  const features = [
    {
      icon: <MapPin size={40} color="#c19a6b" />,
      title: "À proximité",
      description: "À seulement 8 minutes des Chalets De Tesa"
    },
    {
      icon: <Navigation size={40} color="#c19a6b" />,
      title: "Arbo Valley",
      description: "Centre équestre professionnel proposant balades et randonnées"
    },
    {
      icon: <Clock size={40} color="#c19a6b" />,
      title: "Flexibilité",
      description: "Différentes formules adaptées à tous les niveaux"
    }
  ];

  const handleRedirect = () => {
    window.open('https://www.arbovalley.com/', '_blank');
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '120px',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
          zIndex: 1
        }}
      />

      <Box sx={{ 
        py: 8, 
        backgroundColor: '#f5f5f5',
        position: 'relative',
        zIndex: 0
      }}>
        <Container maxWidth="lg">
          <SlideInAlternating delay={0.2}>
            <Box sx={{ 
              textAlign: 'center', 
              mb: 6,
              pt: { xs: 4, md: 8 }
            }}>
              <Typography
                component="h1"
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  color: '#1a1a1a',
                  mb: 2
                }}
              >
                Balades à Cheval
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '1.1rem',
                  color: '#666',
                  maxWidth: '800px',
                  margin: '0 auto'
                }}
              >
                Découvrez la beauté de la Corse à cheval avec notre partenaire Arbo Valley
              </Typography>
            </Box>
          </SlideInAlternating>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SlideInAlternating delay={0.4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                      backgroundColor: 'white', 
                      p: 4, 
                      borderRadius: '8px',
                      height: '100%',
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '2px',
                        height: '100%',
                        background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                      }
                    }}>
                      <Box sx={{ pl: 4 }}>
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: 'Playfair Display',
                            mb: 3,
                            color: '#1a1a1a'
                          }}
                        >
                          Une expérience unique
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Raleway',
                            color: '#666',
                            mb: 4,
                            lineHeight: 1.8
                          }}
                        >
                          À seulement 8 minutes des Chalets De Tesa, le domaine d'Arbo Valley vous accueille pour des balades et randonnées à cheval inoubliables. Profitez d'un cadre exceptionnel pour découvrir la Corse autrement.
                        </Typography>
                        <Button
                          onClick={handleRedirect}
                          sx={{
                            backgroundColor: '#c19a6b',
                            color: 'white',
                            padding: '12px 24px',
                            borderRadius: '4px',
                            textTransform: 'none',
                            fontFamily: 'Raleway',
                            '&:hover': {
                              backgroundColor: '#a67c52',
                              transform: 'translateY(-2px)',
                              boxShadow: '0 2px 8px rgba(193, 154, 107, 0.3)'
                            }
                          }}
                        >
                          Visiter le site d'Arbo Valley
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '400px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                      }}
                    >
                      <Box
                        component="img"
                        src="/arbo.jpg"
                        alt="Balade à cheval"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </SlideInAlternating>
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ mt: 4 }}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <SlideInAlternating delay={0.2 * (index + 1)} fromLeft={index % 2 === 0}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      padding: '2rem',
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      height: '100%',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-10px)'
                      }
                    }}
                  >
                    <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: 'Playfair Display',
                        mb: 2,
                        color: '#1a1a1a'
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        color: '#666'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </SlideInAlternating>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default BaladeCheval;