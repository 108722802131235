import React from 'react';
import { motion } from 'framer-motion';

export const FadeIn = ({ 
  children, 
  delay = 0, 
  duration = 0.6, 
  triggerOnScroll = false,
  margin = "-100px" 
}) => {
  const animation = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: {
      duration: duration,
      delay: delay,
      ease: [0.43, 0.13, 0.23, 0.96]
    }
  };

  if (triggerOnScroll) {
    return (
      <motion.div
        initial={animation.initial}
        whileInView={animation.animate}
        viewport={{ once: true, margin: margin }}
        transition={animation.transition}
      >
        {children}
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={animation.initial}
      animate={animation.animate}
      transition={animation.transition}
    >
      {children}
    </motion.div>
  );
};