import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { FadeIn } from '../../animations/FadeIn';

export const HeroBanner = () => (
  <Box 
    sx={{ 
      height: '40vh',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url("/d3.JPG")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'brightness(0.3)',
        zIndex: -1
      }
    }}
  >
    <Container maxWidth="lg" sx={{ textAlign: 'center', position: 'relative', pt: 8 }}>
      <FadeIn>
        <Typography 
          variant="h1" 
          sx={{
            fontFamily: 'Playfair Display',
            color: 'white',
            fontSize: { xs: '2.5rem', md: '4rem' },
            mb: 2
          }}
        >
          Contact
        </Typography>
      </FadeIn>
      <FadeIn delay={0.2}>
        <Typography 
          sx={{
            fontFamily: 'Raleway',
            color: '#F5E6D3',
            fontSize: { xs: '1.1rem', md: '1.3rem' },
            maxWidth: '600px',
            margin: '0 auto'
          }}
        >
          Planifiez votre séjour de rêve dans nos chalets d'exception
        </Typography>
      </FadeIn>
    </Container>
  </Box>
);