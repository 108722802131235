import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { Coffee, Croissant, Apple } from 'lucide-react';
import SlideInAlternating from '../../animations/SlideInAlternating';
import { FadeIn } from '../../animations/FadeIn';

const PetitDejeuner = () => { 
  const menuItems = [
    { 
      Icon: Croissant, 
      title: "Viennoiseries", 
      description: "Une sélection de viennoiseries fraîches livrées chaque matin : croissants, pains au chocolat et baguette." 
    },
    { 
      Icon: Apple, 
      title: "Boissons fraîches", 
      description: "Une sélection de jus de fruits pour un réveil vitaminé et rafraîchissant." 
    },
    { 
      Icon: Coffee, 
      title: "Boissons Chaudes", 
      description: "Un large choix de boissons chaudes : café, thés parfumés et chocolat chaud onctueux, à volonté." 
    }
  ];

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '120px',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
          zIndex: 1
        }}
      />

      <Box sx={{ 
        py: { xs: 12, md: 16 },
        backgroundColor: '#f5f5f5',
        position: 'relative',
        zIndex: 0,
      }}>
        <Container maxWidth="lg">
          {/* Hero Section */}
          <Grid container spacing={6} alignItems="center" sx={{ mb: 8 }}>
            <Grid item xs={12} md={6}>
              <SlideInAlternating delay={0.1} fromLeft={true}>
                <Box sx={{ pr: { md: 4 } }}>
                  <Typography
                    component="h1"
                    sx={{
                      fontFamily: 'Playfair Display',
                      fontSize: { xs: '2.5rem', md: '3.5rem' },
                      color: '#1a1a1a',
                      mb: 3,
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: '-10px',
                        left: 0,
                        width: '60px',
                        height: '2px',
                        backgroundColor: '#c19a6b'
                      }
                    }}
                  >
                    Le Petit-Déjeuner
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Raleway',
                      fontSize: '1.1rem',
                      color: '#666',
                      mb: 4,
                      lineHeight: 1.8
                    }}
                  >
                    Découvrez notre petit-déjeuner continental complet composé de viennoiseries fraîches servi sur votre terrasse entre 7h et 10h.
                  </Typography>
                  <FadeIn delay={0.3} triggerOnScroll={true}>
                    <Box
                      sx={{
                        backgroundColor: '#fff',
                        p: 3,
                        borderRadius: '8px',
                        border: '1px solid #c19a6b',
                        mb: 3
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Playfair Display',
                          fontSize: '1.2rem',
                          color: '#1a1a1a',
                          mb: 2
                        }}
                      >
                        Tarifs
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Raleway',
                          color: '#666',
                          '& strong': {
                            color: '#c19a6b',
                            fontWeight: 600
                          }
                        }}
                      >
                        <strong>15€</strong> par personne<br/>
                        ou inclus dans votre formule de séjour
                      </Typography>
                    </Box>
                  </FadeIn>
                </Box>
              </SlideInAlternating>
            </Grid>
            <Grid item xs={12} md={6}>
              <SlideInAlternating delay={0.1} fromLeft={false}>
                <Box
                  sx={{
                    width: '100%',
                    height: '500px',
                    position: 'relative',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }}
                >
                  <Box
                    component="img"
                    src="/d8.jpg"
                    alt="Petit-déjeuner présentation"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>
              </SlideInAlternating>
            </Grid>
          </Grid>

          {/* Menu Details Section */}
          <FadeIn delay={0.4} triggerOnScroll={true}>
            <Box 
              sx={{ 
                backgroundColor: 'white',
                borderRadius: '8px',
                mb: 8,
                overflow: 'hidden'
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 4,
                      textAlign: 'center',
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '100px',
                        height: '2px',
                        background: 'linear-gradient(to right, transparent, #c19a6b, transparent)',
                      }
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: 'Playfair Display',
                        color: '#1a1a1a',
                        mb: 2
                      }}
                    >
                      Notre Sélection
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        color: '#666',
                        fontSize: '1.1rem'
                      }}
                    >
                      Un petit-déjeuner gourmand avec des produits de qualité
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={0}>
                  {menuItems.map((item, index) => (
                    <Grid item xs={12} md={4} key={index}>
                      <SlideInAlternating delay={0.1 * (index + 4)} fromLeft={index % 2 === 0}>
                        <Box
                          sx={{
                            p: 4,
                            height: '100%',
                            position: 'relative',
                            borderLeft: { md: index > 0 ? '1px solid rgba(193, 154, 107, 0.2)' : 'none' },
                            borderRight: { md: index < 2 ? '1px solid rgba(193, 154, 107, 0.2)' : 'none' },
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-5px)',
                              backgroundColor: '#faf7f3'
                            }
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              height: '100%'
                            }}
                          >
                            <item.Icon size={40} color="#c19a6b" />
                            <Typography
                              sx={{
                                fontFamily: 'Playfair Display',
                                fontSize: '1.4rem',
                                color: '#1a1a1a',
                                mt: 2,
                                mb: 2
                              }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: 'Raleway',
                                color: '#666',
                                lineHeight: 1.8
                              }}
                            >
                              {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </SlideInAlternating>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </FadeIn>

          {/* Image Gallery */}
          <Grid 
            container 
            spacing={4} 
            sx={{ 
              px: { xs: 0, md: 8 },
              justifyContent: 'center'
            }}
          >
            {['/d1.PNG', '/d4.jpg'].map((img, index) => (
              <Grid item xs={12} md={6} key={index}>
                <SlideInAlternating delay={0.1 * (index + 8)} fromLeft={index % 2 === 0}>
                  <Box
                    sx={{
                      width: '100%',
                      height: { xs: '400px', md: '600px' },
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: '8px',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    }}
                  >
                    <Box
                      component="img"
                      src={img}
                      alt={`Petit-déjeuner ambiance ${index + 1}`}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)'
                        }
                      }}
                    />
                  </Box>
                </SlideInAlternating>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ height: { xs: 40, md: 60 } }} />
        </Container>
      </Box>
    </Box>
  );
};

export default PetitDejeuner;