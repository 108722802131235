import React from 'react';
import { Box, Container, Typography, Card, Grid } from '@mui/material';
import CabinIcon from '@mui/icons-material/Cabin';
import NatureIcon from '@mui/icons-material/Nature';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import { useNavigate } from 'react-router-dom';

const HomeFeatures = () => {
  const features = [
    {
      icon: <CabinIcon sx={{ fontSize: 40, color: '#c19a6b' }} />,
      title: "Charme Rustique & Confort Moderne",
      description: "Découvrez nos chalets alliant authenticité et confort moderne pour une expérience unique"
    },
    {
      icon: <NatureIcon sx={{ fontSize: 40, color: '#c19a6b' }} />,
      title: "Immersion Nature Corse",
      description: "Profitez d'un cadre naturel exceptionnel au cœur de la nature corse"
    },
    {
      icon: <HotelIcon sx={{ fontSize: 40, color: '#c19a6b' }} />,
      title: "Luxe Redéfini",
      description: "Une expérience hôtelière repensée pour votre plus grand confort"
    },
    {
      icon: <LocalCafeIcon sx={{ fontSize: 40, color: '#c19a6b' }} />,
      title: "Services Premium",
      description: "Petit-déjeuner inclus et services personnalisés pour un séjour inoubliable"
    }
  ];

  const CustomButton = ({ children }) => {
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/nos-chalets');
      window.scrollTo(0, 0);
    };

    return (
      <button
        onClick={handleClick}
        style={{
          backgroundColor: '#c19a6b',
          color: 'white',
          padding: '12px 24px',
          border: 'none',
          borderRadius: '4px',
          fontFamily: 'Raleway',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#a17f55'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#c19a6b'}
      >
        {children}
      </button>
    );
  };

  const FeatureImage = ({ src, alt }) => (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width: '100%',
        height: '350px',
        objectFit: 'cover',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
      }}
    />
  );

  return (
    <Box 
      id="features-section" 
      sx={{ 
        py: 8, 
        backgroundColor: '#f5f5f5',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        {/* Section Title */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography
            component="h2"
            sx={{
              fontFamily: 'Playfair Display',
              fontSize: '2.5rem',
              color: '#1a1a1a',
              mb: 2
            }}
          >
            Échappée Romantique en Corse
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Raleway',
              fontSize: '1.1rem',
              color: '#666',
              maxWidth: '800px',
              margin: '0 auto'
            }}
          >
            Découvrez nos chalets en bois nichés dans un écrin de nature corse, alliant charme authentique et confort moderne
          </Typography>
        </Box>

        {/* Features Grid */}
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                elevation={0}
                sx={{
                  textAlign: 'center',
                  padding: '2rem',
                  height: '100%',
                  backgroundColor: 'white',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                  }
                }}
              >
                <Box sx={{ mb: 2 }}>
                  {feature.icon}
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Playfair Display',
                    mb: 1,
                    color: '#1a1a1a'
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    color: '#666'
                  }}
                >
                  {feature.description}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Luxury Section */}
        <Box sx={{ 
          position: 'relative',
          mt: 8,
          mb: 8,
          width: '100%'
        }}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                pl: { md: 4 },
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  left: { xs: '0', md: '0' },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '2px',
                  height: '80%',
                  background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                }
              }}>
                <Box sx={{ pl: { md: 4 } }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Playfair Display',
                      mb: 2,
                      color: '#1a1a1a'
                    }}
                  >
                    Le luxe redéfini
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Raleway',
                      mb: 3,
                      color: '#666'
                    }}
                  >
                    Nos chalets sont conçus pour vous offrir une expérience de séjour unique, où le luxe se mêle à la simplicité de la vie en pleine nature. Découvrez votre paradis privé au cœur de la Corse.
                  </Typography>
                  <CustomButton>En savoir plus</CustomButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FeatureImage
                src="/i3.JPG"
                alt="Intérieur luxueux du chalet"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Nature Section */}
        <Box sx={{ 
          position: 'relative',
          mt: 8,
          width: '100%'
        }}>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <FeatureImage
                src="/poney.PNG"
                alt="Vue sur les poneys depuis le chalet"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                pl: { md: 4 },
                position: 'relative',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  right: { xs: '0', md: '0' },
                  top: '50%',
                  transform: 'translateY(-50%)',
                  width: '2px',
                  height: '80%',
                  background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                }
              }}>
                <Box sx={{ pr: { md: 4 } }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: 'Playfair Display',
                      mb: 2,
                      color: '#1a1a1a'
                    }}
                  >
                    Une expérience insolite
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Raleway',
                      mb: 3,
                      color: '#666'
                    }}
                  >
                    Vivez une expérience unique : dormez face aux poneys en pleine nature corse. Un réveil magique avec une vue imprenable sur vos voisins à quatre pattes.
                  </Typography>
                  <CustomButton>Découvrir cette expérience</CustomButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeFeatures;