export const textFieldStyle = {
    mb: 3,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&:hover fieldset': {
        borderColor: '#c19a6b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c19a6b',
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#c19a6b',
    },
  };
  
  export const submitButtonStyle = {
    backgroundColor: '#c19a6b',
    color: 'white',
    padding: '14px',
    fontSize: '1.1rem',
    '&:hover': {
      backgroundColor: '#a67c52',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(193, 154, 107, 0.4)',
    },
    '&:disabled': {
      backgroundColor: '#cccccc',
      transform: 'none',
      boxShadow: 'none',
    },
    transition: 'all 0.3s ease',
    fontFamily: 'Raleway',
    textTransform: 'none'
  };