import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  IconButton, 
  Box,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Collapse,
  Popper,
  Paper,
  Grow
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import CabinIcon from '@mui/icons-material/Cabin';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import SpaIcon from '@mui/icons-material/Spa';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { FoodBank } from '@mui/icons-material';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from 'react-router-dom';
import { FaGolfBall, FaHorseHead } from 'react-icons/fa';
import './Header.css';

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const servicesRef = React.useRef(null);
  const navigate = useNavigate();

  const handleBookingClick = () => {
    window.open('https://www.booking.com/hotel/fr/les-chalets-de-tesa.fr.html?aid=356980&label=gog235jc-1FMAen2AEB-AEMqAID2AIE&sid=384b5639232bc04cb860d3c7f8a1a6fa&all_sr_blocks=1037528501_397076666_2_2_0;checkin=2024-12-12;checkout=2024-12-13;dest_id=-1455663;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=1037528501_397076666_2_2_0;hpos=1;matching_block_id=1037528501_397076666_2_2_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=1037528501_397076666_2_2_0__18570;srepoch=1730728789;srpvid=3fbf62666fa40581;type=total;ucfs=1&', '_blank');
  };

  const serviceItems = [
    { text: 'Piscine & Détente', icon: <SpaIcon />, path: '/services/piscine-detente' },
    { text: 'Petit Déjeuner', icon: <RestaurantIcon />, path: '/services/petit-dejeuner' },
    { text: 'Vélo & Barbecue', icon: <DirectionsBikeIcon />, path: '/services/velo-barbecue' },
    { text: 'Balade à Cheval', icon: <FaHorseHead />, path: '/services/balade-cheval' },
    { text: "L'Auberge de Tesa", icon: <FoodBank />, path: '/services/auberge-tesa' },
    { text: "Plage de l'Ostriconi", icon: <BeachAccessIcon />, path: '/services/plage-ostriconi' },
    { text: 'Golf du Reginu', icon: <FaGolfBall />, path: '/services/golf-reginu' },
  ];

  const menuItems = [
    { text: 'Accueil', icon: <HomeIcon />, path: '/acceuil' },
    { text: 'Nos Chalets', icon: <CabinIcon />, path: '/nos-chalets' },
    { text: 'Contact', icon: <EmailIcon />, path: '/contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleServicesOpen = () => {
    setOpenServices(true);
  };

  const handleServicesClose = () => {
    setOpenServices(false);
  };

  const handleMobileServicesToggle = () => {
    setMobileServicesOpen(!mobileServicesOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate('/acceuil');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const drawer = (
    <List className="mobile-menu">
      {menuItems.map((item) => (
        <ListItem 
          button 
          component={Link} 
          to={item.path} 
          key={item.text}
          onClick={handleDrawerToggle}
          className="mobile-menu-item"
        >
          <ListItemIcon className="mobile-menu-icon">
            {item.icon}
          </ListItemIcon>
          <ListItemText 
            primary={item.text} 
            className="mobile-menu-text"
          />
        </ListItem>
      ))}

      <ListItem 
        button 
        onClick={handleMobileServicesToggle}
        className="mobile-menu-item"
      >
        <ListItemIcon className="mobile-menu-icon">
          <SpaIcon />
        </ListItemIcon>
        <ListItemText 
          primary="Nos Services" 
          className="mobile-menu-text"
        />
        <ExpandMoreIcon 
          className={`mobile-menu-icon ${mobileServicesOpen ? 'rotate' : ''}`}
        />
      </ListItem>

      <Collapse in={mobileServicesOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {serviceItems.map((item) => (
            <ListItem 
              button 
              component={Link} 
              to={item.path} 
              key={item.text}
              onClick={handleDrawerToggle}
              className="mobile-submenu-item"
            >
              <ListItemIcon className="mobile-menu-icon">
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text} 
                className="mobile-menu-text"
              />
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem 
        button 
        onClick={() => {
          handleBookingClick();
          handleDrawerToggle();
        }}
        className="mobile-menu-item"
      >
        <ListItemIcon className="mobile-menu-icon">
          <BookOnlineIcon />
        </ListItemIcon>
        <ListItemText 
          primary="Réserver" 
          className="mobile-menu-text"
        />
      </ListItem>
    </List>
  );

  return (
    <Box>
      <AppBar 
        position="fixed" 
        className={`navbar ${isScrolled ? 'navbar-scrolled' : 'navbar-transparent'}`}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters className="toolbar">
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                onClick={handleDrawerToggle}
                className="menu-icon"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box
              component="a"
              onClick={handleLogoClick}
              className="navbar-logo-container"
            >
              <img 
                src="/logob1.png" 
                alt="Chalets De Tesa"
                className="navbar-logo-image"
              />
            </Box>

            <Box className="desktop-menu">
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  component={Link}
                  to={item.path}
                  className="menu-button"
                >
                  <Box component="span" className="menu-icon">
                    {item.icon}
                  </Box>
                  {item.text}
                </Button>
              ))}

              <div 
                onMouseEnter={handleServicesOpen}
                onMouseLeave={handleServicesClose}
                ref={servicesRef}
                className="services-container"
              >
                <Button
                  className="menu-button"
                  endIcon={<ExpandMoreIcon className={openServices ? 'rotate' : ''} />}
                >
                  <Box component="span" className="menu-icon">
                    <SpaIcon />
                  </Box>
                  Nos Services
                </Button>

                <Popper
                  open={openServices}
                  anchorEl={servicesRef.current}
                  placement="bottom-start"
                  transition
                  className="services-popper"
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper className="services-menu">
                        {serviceItems.map((item) => (
                          <MenuItem
                            key={item.text}
                            component={Link}
                            to={item.path}
                            className="services-menu-item"
                          >
                            <ListItemIcon className="services-menu-icon">
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                          </MenuItem>
                        ))}
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>

              <Button
                onClick={handleBookingClick}
                className="booking-button"
              >
                Réserver
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        className="mobile-drawer"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Header;