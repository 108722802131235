import React from 'react';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/chalets_tesa?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==', '_blank');
  };
  
  const handleFacebookClick = () => {
    window.open('https://www.facebook.com/p/Haras-de-Tesa-100063649294061/?locale=fr_FR', '_blank');
  };

  const handleHarasClick = () => {
    window.open('https://www.harasdetesa.com/', '_blank');
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="main-sections">
          {/* Logo et Texte */}
          <div className="footer-section">
            <h3>Chalets De Tesa</h3>
            <p>Un séjour paisible et ressourçant au cœur de la nature corse.</p>
          </div>
          {/* Réseaux sociaux */}
          <div className="footer-section">
            <h4>Suivez-nous</h4>
            <div className="social-links">
              <button 
                onClick={handleInstagramClick}
                className="social-button"
                aria-label="Suivez-nous sur Instagram"
              >
                <FaInstagram />
              </button>
              <button 
                onClick={handleFacebookClick}
                className="social-button"
                aria-label="Suivez-nous sur Facebook"
              >
                <FaFacebookF />
              </button>
              <button 
                onClick={handleHarasClick}
                className="social-button"
                aria-label="Visitez le Haras de Tesa"
              >
                <img 
                  src="/logo3.JPG" 
                  alt="Logo Haras de Tesa" 
                  style={{
                    width: '50px',
                    height: '50px',
                    objectFit: 'contain'
                  }}
                />
              </button>
            </div>
          </div>
          {/* Contact */}
          <div className="footer-section">
            <h4>Contact</h4>
            <p>Email: harasdetesa@gmail.com</p>
            <p>Téléphone: +33 6 10 50 34 29</p>
            <p>Adresse: Lieu-dit Tesa, 20226 Occhiatana, Corse</p>
          </div>
        </div>
        {/* Label Image */}
        <div className="footer-label">
          <img src="/label.jpg" alt="Label" className="label-image" />
        </div>
      </div>
      {/* Copyright */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Chalets De Tesa. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;