import React from 'react';
import { Box, Typography } from '@mui/material';

const Separator = () => {
  return (
    <Box 
      sx={{ 
        width: '100%',
        textAlign: 'center',
        mb: 8,
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          height: '1px',
          background: 'linear-gradient(to right, rgba(193, 154, 107, 0), rgba(193, 154, 107, 0.5), rgba(193, 154, 107, 0))'
        }
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          position: 'relative',
          px: 4,
          py: 1,
          backgroundColor: '#f5f5f5',
          color: '#c19a6b',
        }}
      >
        <Typography 
          sx={{ 
            fontFamily: 'Playfair Display',
            fontSize: '1.5rem',
            fontStyle: 'italic'
          }}
        >
          ✦
        </Typography>
      </Box>
    </Box>
  );
};

export default Separator;