import React from 'react';
import { Box, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FadeIn } from '../../animations/FadeIn';

const ContactCard = ({ icon: Icon, title, content, delay }) => (
  <FadeIn triggerOnScroll delay={delay}>
    <Box sx={{ 
      backgroundColor: 'white',
      p: 3,
      borderRadius: 2,
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
      display: 'flex',
      alignItems: 'center',
      gap: 2
    }}>
      <Icon sx={{ color: '#c19a6b', fontSize: 30 }} />
      <Box>
        <Typography sx={{ fontFamily: 'Playfair Display', mb: 0.5 }}>
          {title}
        </Typography>
        <Typography sx={{ fontFamily: 'Raleway', color: '#666' }}>
          {content}
        </Typography>
      </Box>
    </Box>
  </FadeIn>
);

export const ContactInfo = () => (
  <Box sx={{ flex: 1 }}>
    <FadeIn triggerOnScroll>
      <Typography 
        variant="h4" 
        sx={{
          fontFamily: 'Playfair Display',
          color: '#1a1a1a',
          mb: 4
        }}
      >
        Informations de contact
      </Typography>
    </FadeIn>
    
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <ContactCard
        icon={EmailIcon}
        title="Email"
        content="harasdetesa@gmail.com"
        delay={0.2}
      />
      <ContactCard
        icon={LocationOnIcon}
        title="Adresse"
        content="Lieu dit Tesa, 20226 Occhiatana, Corse"
        delay={0.3}
      />
    </Box>

    <FadeIn triggerOnScroll delay={0.4}>
  <Box sx={{ mt: 4, borderRadius: 2, overflow: 'hidden', boxShadow: '0 4px 20px rgba(0,0,0,0.08)', height: '300px' }}>
    <iframe
      title="Carte des Haras de Tesa - Les chalets de Tesa"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2935.9914198106994!2d8.992286076715265!3d42.61913797116998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d0c599b1fdeca7%3A0x43bcf48cba5f1651!2sHaras%20de%20Tesa%20-%20Les%20chalets%20de%20Tesa!5e0!3m2!1sfr!2sfr!4v1730730714201!5m2!1sfr!2sfr&t=k"
      width="100%"
      height="100%"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  </Box>
</FadeIn>
  </Box>
);