import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import WifiIcon from '@mui/icons-material/Wifi';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import TvIcon from '@mui/icons-material/Tv';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SecurityIcon from '@mui/icons-material/Security';
import ShowerIcon from '@mui/icons-material/Shower';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import PoolIcon from '@mui/icons-material/Pool';
import { amenities } from '../chalets/Data';

const getIcon = (iconName) => {
  const icons = {
    tv: <TvIcon sx={{ fontSize: 40 }}/>,
    shower: <ShowerIcon sx={{ fontSize: 40 }}/>,
    wifi: <WifiIcon sx={{ fontSize: 40 }}/>,
    parking: <LocalParkingIcon sx={{ fontSize: 40 }}/>,
    ac: <AcUnitIcon sx={{ fontSize: 40 }}/>,
    restaurant: <RestaurantIcon sx={{ fontSize: 40 }}/>,
    bar: <LocalBarIcon sx={{ fontSize: 40 }}/>,
    security: <SecurityIcon sx={{ fontSize: 40 }}/>,
    piscine: <PoolIcon sx={{ fontSize: 40 }}/>
  };
  return icons[iconName];
};

const Amenities = () => {
  return (
    <>
      <Typography 
        variant="h4" 
        sx={{
          fontFamily: 'Playfair Display',
          color: '#1a1a1a',
          mb: 4,
          textAlign: 'center'
        }}
      >
        Équipements et services
      </Typography>
      
      <Grid container spacing={3} sx={{ mb: 4 }}> {/* Réduit mb de 8 à 4 */}
        {amenities.map((amenity, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box
              sx={{
                backgroundColor: 'white',
                p: 3,
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                }
              }}
            >
              <Box sx={{ color: '#c19a6b', mb: 2 }}>
                {getIcon(amenity.icon)}
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: '1.2rem',
                  mb: 1
                }}
              >
                {amenity.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  color: '#666',
                  fontSize: '0.9rem'
                }}
              >
                {amenity.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Note sur le ménage et le linge */}
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '800px',
          margin: '0 auto',
          mb: 8,
          p: 3,
          borderTop: '1px solid rgba(193, 154, 107, 0.2)',
          borderBottom: '1px solid rgba(193, 154, 107, 0.2)'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Raleway',
            color: '#666',
            fontSize: '0.95rem',
            fontStyle: 'italic',
            lineHeight: 1.8
          }}
        >
          Pour votre confort, le ménage est effectué quotidiennement et l'ensemble du linge de lit et de bain est fourni. Nos équipes veillent à maintenir les plus hauts standards de propreté et de confort tout au long de votre séjour.
        </Typography>
      </Box>
    </>
  );
};

export default Amenities;