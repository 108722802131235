import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0.8 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.8 }}
      transition={{
        duration: 0.15,
        ease: 'linear'
      }}
      onAnimationStart={() => {
        window.scrollTo({
          top: 0,
          behavior: 'instant'
        });
      }}
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;