import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { tarifs } from '../chalets/Data';

const Tarifs = () => {
  return (
    <>
      <Typography 
        variant="h4" 
        sx={{
          fontFamily: 'Playfair Display',
          color: '#1a1a1a',
          mb: 4,
          textAlign: 'center'
        }}
      >
        Tarifs à la nuitée pour deux personnes
      </Typography>

      <Typography 
        sx={{
          fontFamily: 'Raleway',
          color: '#666',
          fontSize: '1rem',
          mb: 2,
          textAlign: 'center'
        }}
      >
        (2 adultes ou 1 adulte et 1 enfant de 12 ans ou plus, animaux non admis)
      </Typography>

      <Typography 
        sx={{
          fontFamily: 'Playfair Display',
          color: '#c19a6b',
          fontSize: '1.2rem',
          mb: 6,
          textAlign: 'center',
          fontStyle: 'italic'
        }}
      >
        Établissement ouvert à l'année
      </Typography>

      <Grid container spacing={3}>
        {tarifs.map((tarif, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: 'white',
                p: 4,
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                }
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: '1.2rem',
                  mb: 2,
                  minHeight: '60px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {tarif.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: '2rem',
                  color: '#c19a6b',
                  mb: 2
                }}
              >
                {tarif.price}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  color: '#666',
                  fontSize: '0.9rem'
                }}
              >
                {tarif.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          mt: 6,
          p: 4,
          backgroundColor: '#f8f5f1',
          borderRadius: 2,
          textAlign: 'center'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Playfair Display',
            fontSize: '1.3rem',
            color: '#c19a6b',
            mb: 2
          }}
        >
          Modalités de réservation
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            color: '#666',
            fontSize: '1rem',
            mb: 1
          }}
        >
          Réservation possible en ligne sur notre site ou par téléphone
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            color: '#666',
            fontSize: '1rem',
            mb: 1
          }}
        >
          Acompte de 50% à la réservation non remboursable • Solde à régler à l'arrivée
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Raleway',
            fontSize: '1rem',
            color: '#c19a6b',
            fontStyle: 'italic'
          }}
        >
          Cartes cadeaux BOXETTA acceptées
        </Typography>
      </Box>
    </>
  );
};

export default Tarifs;