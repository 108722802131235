import React from 'react';
import { Box, Container } from '@mui/material';
import { HeroBanner } from '../components/contact/HeroBanner';
import { ContactForm } from '../components/contact/ContactForm';
import { ContactInfo } from '../components/contact/ContactInfo';

const ContactPage = () => {
  return (
    <>
      <HeroBanner />
      <Box sx={{ 
        backgroundColor: '#f5f5f5',
        position: 'relative',
        py: 8,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: -50,
          left: 0,
          right: 0,
          height: '100px',
          background: '#f5f5f5',
          transform: 'skewY(-2deg)',
          transformOrigin: '100%'
        }
      }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', lg: 'row' }, 
            gap: 6, 
            position: 'relative' 
          }}>
            <Box sx={{ order: { xs: 2, lg: 1 } }}>
              <ContactForm />
            </Box>
            <Box sx={{ order: { xs: 1, lg: 2 } }}>
              <ContactInfo />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ContactPage;