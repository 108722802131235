import React from 'react';
import HeroBanner from '../components/accueil/HeroBanner';
import HomeFeatures from '../components/accueil/HomeFeatures';
import Testimonials from '../components/accueil/Testimonials';
import { FadeIn } from '../animations/FadeIn';

const Home = () => {
  return (
    <main className="home">
      <HeroBanner />
      <FadeIn 
        triggerOnScroll={true} 
        duration={0.8} 
        delay={0.3}
        margin="-150px"
      >
      <HomeFeatures />
      </FadeIn>

      <FadeIn 
        triggerOnScroll={true} 
        duration={0.8} 
        delay={0.3}
        margin="-150px"
      >
      <Testimonials />
      </FadeIn>
    </main>
  );
};

export default Home;
