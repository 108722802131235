import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { Waves, Palmtree, Sun } from 'lucide-react';
import SlideInAlternating from '../../animations/SlideInAlternating';

const PiscineDetente = () => {
  const features = [
    {
      icon: <Waves size={40} color="#c19a6b" />,
      title: "Piscine Spacieuse",
      description: "Une piscine de 9,20m x 5,20m pour profiter des journées ensoleillées"
    },
    {
      icon: <Palmtree size={40} color="#c19a6b" />,
      title: "Espace Détente",
      description: "Transats confortables et hamacs pour une relaxation totale"
    },
    {
      icon: <Sun size={40} color="#c19a6b" />,
      title: "Vue Panoramique",
      description: "Une vue imprenable sur les chevaux du Haras et les montagnes environnantes"
    }
  ];

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '120px',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
          zIndex: 1
        }}
      />

      <Box sx={{ 
        py: 8, 
        backgroundColor: '#f5f5f5',
        position: 'relative',
        zIndex: 0
      }}>
        <Container maxWidth="lg">
          <SlideInAlternating delay={0.2}>
            <Box sx={{ 
              textAlign: 'center', 
              mb: 6,
              pt: { xs: 4, md: 8 }
            }}>
              <Typography
                component="h1"
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  color: '#1a1a1a',
                  mb: 2
                }}
              >
                Piscine & Détente
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '1.1rem',
                  color: '#666',
                  maxWidth: '800px',
                  margin: '0 auto'
                }}
              >
                Un espace de détente privilégié avec vue panoramique sur la nature corse
              </Typography>
            </Box>
          </SlideInAlternating>

          <SlideInAlternating delay={0.2} fromLeft={false}>
            <Box
              sx={{
                width: '100%',
                height: { xs: '300px', md: '500px' },
                position: 'relative',
                mb: 6,
                overflow: 'hidden',
                borderRadius: '8px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
              }}
            >
              <Box
                component="img"
                src="/piscine.jpg"
                alt="Piscine vue panoramique"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </Box>
          </SlideInAlternating>

          <Grid container spacing={4} sx={{ mb: 8 }}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <SlideInAlternating delay={0.2 * (index + 3)} fromLeft={index % 2 === 0}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      padding: '2rem',
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      height: '100%',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-10px)'
                      }
                    }}
                  >
                    <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: 'Playfair Display',
                        mb: 2,
                        color: '#1a1a1a'
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        color: '#666'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </SlideInAlternating>
              </Grid>
            ))}
          </Grid>
          <SlideInAlternating delay={0.4}>
            <Box sx={{ 
              backgroundColor: 'white', 
              p: 4, 
              borderRadius: '8px',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                width: '2px',
                height: '100%',
                background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
              }
            }}>
              <Box sx={{ pl: 4 }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: 'Playfair Display',
                    mb: 3,
                    color: '#1a1a1a'
                  }}
                >
                  Une oasis de détente en pleine nature
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    color: '#666',
                    mb: 2
                  }}
                >
                  Notre piscine de 9,20m x 5,20m est le lieu idéal pour se rafraîchir et se détendre. Bordée d'une élégante terrasse en bois, elle offre un espace de baignade généreux dans un cadre naturel d'exception.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    color: '#666',
                    mb: 2
                  }}
                >
                  Pour votre confort, nous avons aménagé plusieurs espaces de détente autour de la piscine. Des transats confortables vous permettent de vous prélasser au soleil, tandis que nos hamacs, vous invitent à la sieste ou à la contemplation du paysage environnant.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Raleway',
                    color: '#666'
                  }}
                >
                  Que vous choisissiez de vous rafraîchir dans l'eau, de vous détendre sur un transat ou de vous balancer, vous profiterez d'une vue imprenable sur la nature corse. C'est l'endroit parfait pour se ressourcer et apprécier l'atmosphère unique que procure les chevaux.
                </Typography>
              </Box>
            </Box>
          </SlideInAlternating>

          <Grid container spacing={10} sx={{ mt: 2 }}>
            <Grid item xs={12} md={8}>
              <SlideInAlternating delay={0.4} fromLeft={true}>
                <Box
                  sx={{
                    width: '100%',
                    height: { xs: '300px', md: '500px' },
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }}
                >
                  <Box
                    component="img"
                    src="/detente.jpg"
                    alt="Espace détente piscine vue large"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>
              </SlideInAlternating>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <SlideInAlternating delay={0.6} fromLeft={false}>
                <Box
                  sx={{
                    width: '100%',
                    height: { xs: '300px', md: '500px' },
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                  }}
                >
                  <Box
                    component="img"
                    src="/detente2.jpg"
                    alt="Espace détente piscine vue portrait"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                  />
                </Box>
              </SlideInAlternating>
            </Grid>
          </Grid>

        </Container>
      </Box>
    </Box>
  );
};

export default PiscineDetente;