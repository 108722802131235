export const images = ['/i4.JPG', '/i5.JPG', '/i8.JPG', 'i7.JPG', 'i6.JPG'];

export const amenities = [
  {
    icon: 'tv',
    title: "Divertissement",
    description: "TV écran plat avec Netflix, Prime Video et plus"
  },
  {
    icon: 'shower',
    title: "Salle de bain moderne",
    description: "Douche cabine avec Bluetooth, radio, éclairage d'ambiance et jets hydromassants"
  },
  {
    icon: 'wifi',
    title: "Wifi gratuit",
    description: "Connexion internet haut débit dans tout le chalet"
  },
  {
    icon: 'parking',
    title: "Parking privé",
    description: "Stationnement spacieux sécurisé et gratuit pour votre véhicule"
  },
  {
    icon: 'ac',
    title: "Climatisation",
    description: "Climatisation et chauffage pour votre confort"
  },
  {
    icon: 'bar',
    title: "Accueil personnalisé",
    description: "Plateau de courtoisie et minibar en extra"
  },
  {
    icon: 'security',
    title: "Sécurité",
    description: "Coffre-fort à disposition, site surveillé"
  },
  {
    icon: 'piscine',
    title: "Piscine",
    description: "Grande piscine extérieure avec transats"
  }
];

export const tarifs = [
  {
    title: "Formule avec petit-déjeuner",
    price: "170€",
    description: "Par nuitée pour deux personnes"
  },
  {
    title: "Formule de base",
    price: "145€",
    description: "Par nuitée pour deux personnes"
  },
  {
    title: "Petit-Déjeuner Continental",
    price: "15€",
    description: "Par personne"
  }
];