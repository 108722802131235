import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { Bike, Flame, Map } from 'lucide-react';
import SlideInAlternating from '../../animations/SlideInAlternating';

const VeloBarbecue = () => {
  const features = [
    {
      icon: <Bike size={40} color="#c19a6b" />,
      title: "Vélos à Disposition",
      description: "Explorez les sentiers pittoresques de la région à vélo"
    },
    {
      icon: <Flame size={40} color="#c19a6b" />,
      title: "Barbecue",
      description: "Profitez de moments conviviaux autour d'un barbecue en plein air"
    },
    {
      icon: <Map size={40} color="#c19a6b" />,
      title: "Cadre Naturel",
      description: "Un environnement paisible pour vos activités de plein air"
    }
  ];

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '120px',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), transparent)',
          zIndex: 1
        }}
      />

      <Box sx={{ 
        py: 8, 
        backgroundColor: '#f5f5f5',
        position: 'relative',
        zIndex: 0
      }}>
        <Container maxWidth="lg">
          <SlideInAlternating delay={0.2}>
            <Box sx={{ 
              textAlign: 'center', 
              mb: 6,
              pt: { xs: 4, md: 8 }
            }}>
              <Typography
                component="h1"
                sx={{
                  fontFamily: 'Playfair Display',
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  color: '#1a1a1a',
                  mb: 2
                }}
              >
                Vélos & Barbecue 
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Raleway',
                  fontSize: '1.1rem',
                  color: '#666',
                  maxWidth: '800px',
                  margin: '0 auto'
                }}
              >
                Découvrez nos équipements pour des moments inoubliables en plein air
              </Typography>
            </Box>
          </SlideInAlternating>
          <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12}>
              <SlideInAlternating delay={0.6}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ 
                      backgroundColor: 'white', 
                      p: 4, 
                      borderRadius: '8px',
                      height: '100%',
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '2px',
                        height: '100%',
                        background: 'linear-gradient(to bottom, transparent, #c19a6b, transparent)',
                      }
                    }}>
                      <Box sx={{ pl: 4 }}>
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: 'Playfair Display',
                            mb: 3,
                            color: '#1a1a1a'
                          }}
                        >
                          Des activités pour tous
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Raleway',
                            color: '#666',
                            mb: 2
                          }}
                        >
                          Profitez de nos vélos mis gracieusement à votre disposition pour explorer les magnifiques sentiers de la région. Une façon écologique et agréable de découvrir les paysages environnants.
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Raleway',
                            color: '#666'
                          }}
                        >
                          Notre espace barbecue aménagé vous permet d'organiser de délicieux repas en plein air. Un moment convivial à partager en famille ou entre amis sous le soleil corse.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        minHeight: '300px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                      }}
                    >
                      <Box
                        component="img"
                        src="/barve1.JPG"
                        alt="Vue environnante"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </SlideInAlternating>
            </Grid>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <SlideInAlternating delay={0.2 * (index + 1)} fromLeft={index % 2 === 0}>
                  <Box
                    sx={{
                      textAlign: 'center',
                      padding: '2rem',
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      height: '100%',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-10px)'
                      }
                    }}
                  >
                    <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: 'Playfair Display',
                        mb: 2,
                        color: '#1a1a1a'
                      }}
                    >
                      {feature.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Raleway',
                        color: '#666'
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </SlideInAlternating>
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SlideInAlternating delay={0.4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '400px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                        mb: 4
                      }}
                    >
                      <Box
                        component="img"
                        src="/barve.jpg"
                        alt="Espace barbecue"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        width: '100%',
                        height: '400px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                        mb: 4
                      }}
                    >
                      <Box
                        component="img"
                        src="/barve2.jpg"
                        alt="Vélos à disposition"
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </SlideInAlternating>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default VeloBarbecue;