import React, { useState, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import { FadeIn } from '../../animations/FadeIn';
import { textFieldStyle, submitButtonStyle } from './styles';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    dateArrivee: '',
    dateDepart: '',
    nombrePersonnes: '',
    message: ''
  });
  
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Veuillez valider le captcha avant d'envoyer le message");
      return;
    }
    window.location.href = `mailto:harasdetesa@gmail.com?subject=Demande de réservation&body=Nom: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0ADate d'arrivée: ${formData.dateArrivee}%0D%0ADate de départ: ${formData.dateDepart}%0D%0ANombre de personnes: ${formData.nombrePersonnes}%0D%0AMessage: ${formData.message}`;
    
    recaptchaRef.current.reset();
    setCaptchaValue(null);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <FadeIn triggerOnScroll>
      <Box sx={{ 
        flex: 1,
        backgroundColor: 'white',
        p: { xs: 3, md: 5 },
        borderRadius: 2,
        boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        alignSelf: 'flex-start'
      }}>
        <Typography 
          variant="h4" 
          sx={{
            fontFamily: 'Playfair Display',
            color: '#1a1a1a',
            mb: 4
          }}
        >
          Réservation
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nom et prénom" 
            name="name"
            value={formData.name}
            onChange={handleChange}
            sx={textFieldStyle}
            required
          />

          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            sx={textFieldStyle}
            required
          />

          <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
            <TextField
              fullWidth
              label="Date d'arrivée"
              name="dateArrivee"
              type="date"
              value={formData.dateArrivee}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={textFieldStyle}
              required
            />
            <TextField
              fullWidth
              label="Date de départ"
              name="dateDepart"
              type="date"
              value={formData.dateDepart}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={textFieldStyle}
              required
            />
          </Box>

          <TextField
            fullWidth
            label="Nombre de personnes"
            name="nombrePersonnes"
            type="number"
            value={formData.nombrePersonnes}
            onChange={handleChange}
            sx={textFieldStyle}
            required
          />

          <TextField
            fullWidth
            label="Message"
            name="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
            sx={{ ...textFieldStyle, mb: 4 }}
            required
          />

          <Box sx={{ mb: 3 }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Ldz4HcqAAAAALXpeRZeOfXIAi4ix6Nv3nIQosmo"
              onChange={(value) => setCaptchaValue(value)}
              theme="light"
              size="normal"
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            disabled={!captchaValue}
            sx={submitButtonStyle}
          >
            Envoyer la demande
          </Button>
        </form>
      </Box>
    </FadeIn>
  );
};